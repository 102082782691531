import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { AppConfigProvider } from "./context/AppContext";
import { AuthProvider } from "./context/AuthContext";
import I18nProvider from "./i18n/I18nProvider";
import AppRoutes from "./routes/AppRoutes";

// Global Styles
import "react-toastify/dist/ReactToastify.css";
import { AppLayoutProvider } from "./context/AppLayoutContext";
import "./index.css";

// Google SDK

export default function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppConfigProvider>
          <I18nProvider>
            <AppLayoutProvider>
              <AppRoutes />
            </AppLayoutProvider>
            <ToastContainer />
          </I18nProvider>
        </AppConfigProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
