import { Switch } from "@headlessui/react";
import clx from "classnames";
export default function AppCheckBox({ value, onChange, ...props }) {
  return (
    <div className="h-12 flex items-center">
      <Switch
        checked={value}
        onChange={onChange}
        className={clx(
          { "bg-blue-600": value },
          { "bg-gray-200": !value },
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={clx(
            { "translate-x-5": value },
            { "translate-x-0": !value },
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </div>
  );
}
