import { API_BASE_URL, CDN_BASE_URL } from ".";

const ADMIN = `${API_BASE_URL}/admin`;

const ENDPOINTS = {
  user: `${ADMIN}/users`,
  me: `${ADMIN}/me`,
  media: `${ADMIN}/medias`,
  lead: `${ADMIN}/leads`,
  company: `${ADMIN}/companies`,
  provider: `${ADMIN}/providers`,
  category: `${ADMIN}/categories`,
  offer: `${ADMIN}/offers`,
  query: `${ADMIN}/queries`,
  policy: `${ADMIN}/policies`,
  report: `${ADMIN}/reports`,
  ticket: `${ADMIN}/tickets`,
  slider: `${ADMIN}/sliders`,
  form: `${ADMIN}/forms`,
  template: `${ADMIN}/templates`,
  shablon: `${ADMIN}/shablons`,
  toll: `${ADMIN}/tolls`,
  commission: `${ADMIN}/commissions`,
  auth: {
    login: `${ADMIN}/auth/login`,
    verify: `${ADMIN}/auth/verify`,
  },
  cdn: CDN_BASE_URL,
};

export default ENDPOINTS;
