import Locale from "@components/core/Locale";

// Calendar Locales
import en_NZ from "date-fns/locale/en-NZ";
import en_US from "date-fns/locale/en-US";
import tr from "date-fns/locale/tr";

import { forwardRef, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppIcon from "../AppIcon";
import "./AppDateTimePicker.css";

const DateTimePickerInput = forwardRef(({ value, onClick }, ref) => (
  <div className="form w-full flex items-center justify-between space-x-6 cursor-pointer" onClick={onClick} ref={ref}>
    <span className="block ">{Boolean(value) ? value : <Locale code="T.SELECT" />}</span>
    <AppIcon code="fa-calendar" className="h-5 w-5" />
  </div>
));

const _getFormat = (format, time = false) => {
  let newFormat = format?.split(" ")[0]?.replace("YYYY", "yyyy").replace("DD", "dd");
  return time ? `${newFormat} HH:m:s` : newFormat;
};

const _LOCALES = {
  tr: tr,
  "en-US": en_US,
  "en-NZ": en_NZ,
};

export default function AppDateTimePicker({ value, onChange, time = false, ...props }) {
  const calendar = "en-US";

  useEffect(() => {
    // Regisetr Calendar Locale
    if (_LOCALES[calendar]) registerLocale(calendar, _LOCALES[calendar]);
  }, [calendar]);

  return (
    <div {...props}>
      <DatePicker
        disabled={props.disabled}
        selected={value ? new Date(value) : undefined}
        onChange={(value) => onChange(time ? value.toISOString() : value.toISOString().split("T")[0])}
        locale={calendar}
        showTimeSelect={time}
        customInput={<DateTimePickerInput value={value} />}
        dateFormat={_getFormat("yyyy-MM-dd h:m:s", time)}
        wrapperClassName="datepicker"
      />
    </div>
  );
}
