const NOTIF_PAYLOAD = {
  // UUID Unique Identifier Set By Server
  UUID_1: {
    display: { name: "", thumbnail: "" }, // How Notification P
    code: "NEW.MESSAGE",
    updatedAt: "",
    payload: { leadID: "", conversationID: "", display: { name: "", thumbnail: "" } },
    count: 1,
    seen: false,
  },
};

// Get All Notifications
const getAll = () => {
  try {
    if (!localStorage.getItem("notifications")) return [];
    const localData = JSON.parse(localStorage.getItem("notifications"));
    const notifications = Object.keys(localData).map((UUID) => ({ UUID, ...localData[UUID] }));
    // Sort By Seen
    notifications.sort((a, b) => (a.seen && !b.seen ? -1 : !a.seen && b.seen ? 1 : a.seen && b.seen ? b.updatedAt - a.updatedAt : 0));
    // Sort By updatedAt
    notifications.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
    return notifications;
  } catch (error) {
    return [];
  }
};

// Seen Single Notification
const seen = (UUID) => {
  const localData = localStorage.getItem("notifications") ? JSON.parse(localStorage.getItem("notifications")) : {};
  if (!localData[UUID]) return;
  localData[UUID] = { ...localData[UUID], seen: true, count: 0 };
  return localStorage.setItem("notifications", JSON.stringify(localData));
};

// Remove All Notifications
const removeAll = () => localStorage.removeItem("notifications");

// Add Notification
const add = (_payload = {}) => {
  const { UUID, code, createdAt, display, ...payload } = _payload;
  if (!UUID) return;
  const localData = localStorage.getItem("notifications") ? JSON.parse(localStorage.getItem("notifications")) : {};
  if (localData[UUID]) localData[UUID] = { ...localData[UUID], seen: false, count: (localData[UUID]?.count ?? 0) + 1, updatedAt: createdAt };
  else localData[UUID] = { display, code, payload, count: 1, seen: false, updatedAt: createdAt };
  return localStorage.setItem("notifications", JSON.stringify(localData));
};

const notificationService = { getAll, removeAll, add, seen };
export default notificationService;
