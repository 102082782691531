import { API_BASE_URL, DESKTOP_NOTIFICATION_CODES } from "@config";
import { AuthContext } from "@context/AuthContext";
import { useContext, useEffect } from "react";
import io from "socket.io-client";
import EventService from "../services/event.service";

const beep1 = new Audio("/media/mp3/beep.mp3");
const beep2 = new Audio("/media/mp3/beep2.mp3");

const _AUDIOS = {
  "NEW.LEAD": beep1,
  "NEW.QUERY": beep1,
  "NEW.TICKET": beep1,
  "NEW.REPORT": beep1,
  "NEW.MESSAGE": beep1,
  "OFFER.ACCEPTED": beep2,
  "OFFER.REMINDER": beep1,
};

const beep = (code) => {
  if (!_AUDIOS[code]) return;
  _AUDIOS[code]
    .play()
    .then(() => {
      return;
    })
    .catch((error) => {
      console.log(error);
      return;
    });
};

export default function useSocket() {
  const { token, isLogedIn } = useContext(AuthContext);

  let socket = undefined;

  useEffect(() => {
    if (!isLogedIn) return;

    const socket = io(API_BASE_URL, {
      auth: {
        token: token,
      },
    });

    DESKTOP_NOTIFICATION_CODES.map((code) => {
      socket.on(code, (payload) => {
        if (payload?.beep) beep(code);
        EventService.emit(code, { ...payload, code });
        return;
      });
    });

    return () => {
      DESKTOP_NOTIFICATION_CODES.map((code) => socket.off(code)); // Remove Listeners
      socket.close(); // Close Socket
    };
  }, [isLogedIn]);

  return { socket };
}
