import AppButton from "@components/AppButton";
import AppDrawer from "@components/drawers/AppDrawer";
import { AppLayoutContext } from "@context/AppLayoutContext";
import { useContext } from "react";
import { NotificationContext } from "src/context/NotificationContext";
import NotificationItem from "./components/NotificationItem";

export default function NotificationsDrawer({}) {
  const { notification: ND } = useContext(AppLayoutContext);
  const { notifications, methods } = useContext(NotificationContext);

  return (
    <AppDrawer
      show={ND.enabled}
      onClose={() => ND.close()}
      title="T.NOTIFICATIONS"
      body={
        <div className="absolute inset-0 overflow-y-auto">
          {notifications?.map((notification, _index) => (
            <NotificationItem {...notification} key={_index} onClick={methods.click} />
          ))}
        </div>
      }
      footer={<AppButton icon="fa-trash" className="w-full" mode="light" onClick={() => methods.removeAll()} />}
    />
  );
}
