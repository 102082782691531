// API Base URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// CDN Base URL
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

// App Name
export const APP_NAME = process.env.REACT_APP_APP_NAME;

// Multi Locale Initial Values
export const MLInitialValues = { en: "", fr: "", ru: "" };

// Image Placeholders
export const PLACEHOLDERS = {
  thumbnail: "/media/avatars/blank.png",
};

// Desktop Notifications
export const DESKTOP_NOTIFICATION_CODES = [
  "NEW.MESSAGE",
  "NEW.TICKET",
  "NEW.REPORT",
  "NEW.LEAD",
  "NEW.QUERY",
  "NEW.TICKET",
  "OFFER.ACCEPTED",
  "OFFER.REMINDER",
];

export const HIDDEN_TOAST_ERROR_CODES = [401];
