import AppImage from "./AppImage";

export default function MediaView({ mime = "image/*", url, preview = false }) {
  let type = mime?.split("/")[0];
  if (type === "application" && mime?.split("/")?.[1] === "pdf" && preview) type = "pdf";
  return (
    <>
      {type === "image" && <img src={url} loading="lazy" className="object-contain w-full h-full" />}
      {type === "video" && <video src={url} muted controls className="aspect-auto w-full h-full" />}
      {type === "audio" && <audio src={url} controls />}
      {type === "pdf" && <iframe src="/sample.pdf#toolbar=0&page=1&view=fitH" className="w-full h-full" />}
      {!["image", "video", "audio", "pdf"].includes(type) && (
        <div className="w-full h-full flex items-center justify-center">
          <AppImage code={mime} className="w-16 h-16" />
        </div>
      )}
    </>
  );
}
