import { Outlet } from "react-router-dom";
import { NotificationProvider } from "src/context/NotificationContext";
import DrawersContainer from "./drawers/DrawersContainer";
import MiniSidebar from "./mini-sidebar/MiniSidebar";

export default function MasterLayout() {
  return (
    <NotificationProvider>
      <div className="flex">
        <div className="bg-white border-r">
          <MiniSidebar />
        </div>
        <div className="flex-grow bg-[#F1F1F3] min-h-screen">
          <Outlet />
        </div>
      </div>
      {/* Global Drawers Cotainer */}
      <DrawersContainer />
    </NotificationProvider>
  );
}
