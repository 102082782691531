import { PLACEHOLDERS } from "@config";
import { AuthContext } from "@context/AuthContext";
import { useContext } from "react";
import MediaView from "./MediaView";

export default function ProtectedMedia({ mediaID, name, mime = "image/", ...props }) {
  const { makeCDNURL } = useContext(AuthContext);
  const url = mediaID ? makeCDNURL(mediaID) : PLACEHOLDERS.thumbnail;
  return <MediaView mime={mime} name={name} url={url} {...props} />;
}
