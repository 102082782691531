import RouteContainer from "@components/core/RouteContainer";
import { lazy } from "react";
const CompanyDashboard = lazy(() => import("./CompanyDashboard"));

export default function DashboardPage() {
  const paths = [
    {
      path: "/",
      title: "Dashboard",
      element: <CompanyDashboard />,
    },
  ];
  return <RouteContainer paths={paths} />;
}
