import clx from "classnames";
import { useIntl } from "react-intl";
import AppIcon from "../AppIcon";
const RowInput = ({ field, form, onChange, FormElement, name, label, icon, guide, placeholder, required, className, ...props }) => {
  const intl = useIntl();
  const _error = form?.errors?.[field?.name];
  return (
    <div className={clx(`grid grid-cols-1`, { "lg:grid-cols-3 gap-y-2 lg:gap-y-0 gap-x-2": label }, className)}>
      {label && (
        <div className="flex flex-col justify-center px-2">
          <label className="text-gray-500 font-medium">
            {icon && <AppIcon code={icon} />}
            {intl.formatMessage({ id: label ?? "-" })}
            {required && <span className="text-red-500 ml-1">*</span>}
          </label>
          {_error && <span className="text-xs text-red-500 leading-none">{intl.formatMessage({ id: _error })}</span>}
        </div>
      )}
      <div className={clx({ "col-span-2": label, "col-span-full": !label })}>
        <FormElement
          name={name}
          placeholder={intl.formatMessage({ id: Boolean(placeholder) ? placeholder : name ?? "-" })}
          value={field?.value}
          _error={_error}
          onChange={(value) => (onChange ? onChange(value) : form?.setFieldValue(field?.name, value))}
          {...props}
        />
      </div>
    </div>
  );
};

export default RowInput;
