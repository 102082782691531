import { IntlProvider } from "react-intl";

import deMessages from "./messages/de.json";
import enMessages from "./messages/en.json";
import trMessages from "./messages/tr.json";

import { useContext } from "react";
import { AuthContext } from "src/context/AuthContext";

const allMessages = {
  en: enMessages,
  tr: trMessages,
  de: deMessages,
};

export default function I18nProvider({ children }) {
  const { user } = useContext(AuthContext);
  const locale = user?.locale ?? "en";
  const messages = allMessages[locale];
  return (
    <IntlProvider locale={locale} messages={messages} onError={() => {}}>
      {children}
    </IntlProvider>
  );
}
