import DateFormat from "@components/DateFormat";
import Locale from "@components/core/Locale";
import { memo } from "react";

const NotificationItem = (notification = {}) => {
  const { UUID, code, display, payload, updatedAt, onClick, seen } = notification;
  return (
    <div className="flex items-center justify-between border-b h-16 px-4 hover:bg-gray-100 cursor-pointer" onClick={() => onClick({ UUID, code, payload })}>
      <div className="flex items-center space-x-4">
        {!seen && <div className="w-2 h-2 bg-blue-500 rounded-full" />}
        <div className="flex flex-col">
          <span className="truncate whitespace-nowrap text-sm font-semibold leading-none text-gray-700">{display?.name}</span>
          <span className="text-gray-500 text-sm">
            <Locale code={code} />
          </span>
        </div>
      </div>
      {updatedAt && (
        <span className="text-gray-500 text-sm whitespace-nowrap">
          <DateFormat date={updatedAt} />
        </span>
      )}
    </div>
  );
};

export default memo(NotificationItem);
