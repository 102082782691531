import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { NotificationContext } from "src/context/NotificationContext";
import MiniSidebarItem from "./components/MiniSidebarItem";

export default function MiniSidebar() {
  const { notifications } = useContext(NotificationContext);

  // Sidebar Items
  const _SIDEBAR = [
    { title: "T.DASHBOARD", path: "/dashboard", icon: "fa-dashboard" },
    { title: "T.LEADS", path: "/leads", icon: "fa-users" },
    { title: "T.POLICIES", path: "/policies", icon: "fa-book" },
    { title: "T.QUERIES", path: "/queries", icon: "fa-question" },
    { title: "T.OFFERS", path: "/offers", icon: "fa-tag" },
    { title: "T.REPORTS", path: "/reports", icon: "fa-document" },
    { title: "T.TICKETS", path: "/tickets", icon: "fa-support" },
    { title: "T.MEDIAS", path: "/medias", icon: "fa-photo" },
    { title: "T.COMMISSIONS", path: "/commissions", icon: "fa-euro" },
    { title: "T.SETTINGS", path: "/settings", icon: "fa-cog" },
    { title: "T.NOTIFICATIONS", notification: notifications?.some(({ seen }) => !seen), path: "/notifications", icon: "fa-bell" },
  ];

  return (
    <div className="hidden md:flex sticky top-0 flex-col flex-shrink-0 w-52">
      {_SIDEBAR.map(({ title, icon, active, path, notification, onClick }, _index) => (
        <Fragment key={_index}>
          {path ? (
            <Link to={path}>
              <MiniSidebarItem title={title} icon={icon} active={active} notification={notification} />
            </Link>
          ) : (
            <MiniSidebarItem title={title} icon={icon} active={active} notification={notification} onClick={onClick} />
          )}
        </Fragment>
      ))}
    </div>
  );
}
