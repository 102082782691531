import { DESKTOP_NOTIFICATION_CODES } from "@config";
import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EventService from "../services/event.service";
import notificationService from "../services/notification.service";

const NotificationContext = createContext();
const { Provider } = NotificationContext;

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  // Hanlde notification Callback
  const _handleNotification = (payload = {}) => {
    // Only Persist "Display" Notifications
    if (payload?.display) notificationService.add(payload);
    console.log(payload);
    return setNotifications((x) => notificationService.getAll());
  };

  //   Remove All Locally Persisted Notifications
  const _handleRemoveAll = () => {
    notificationService.removeAll();
    return setNotifications(() => []);
  };

  // Set Notificationa as "SEEN"
  const _handleSeen = (UUID) => {
    notificationService.seen(UUID);
    return setNotifications(() => notificationService.getAll());
  };

  // Register Listeners
  useEffect(() => {
    setNotifications(() => notificationService.getAll());
    EventService.on(DESKTOP_NOTIFICATION_CODES, _handleNotification);
    return () => {
      EventService.off(DESKTOP_NOTIFICATION_CODES, _handleNotification);
    };
  }, []);

  // Handle Click
  const _handleClick = useCallback(({ code, payload, UUID }) => {
    if (![code, payload, UUID].every(Boolean)) return console.log("Invalid Parameters In Notification Item");
    _handleSeen(UUID);
    switch (code) {
      case "NEW.MESSAGE":
        if (payload?.reportID) return navigate(`/leads/${payload.leadID}/profile/reports/${payload.reportID}`); // Opens Report Panel
        else return navigate(`/leads/${payload.leadID}/profile/tickets/${payload.ticketID}`); // Opens Ticket Panel
      case "NEW.TICKET":
        return navigate(`/leads/${payload.leadID}/profile/tickets/${payload.ticketID}`);
      case "NEW.REPORT":
        return navigate(`/leads/${payload.leadID}/profile/reports/${payload.reportID}`);
      case "NEW.TICKET":
        return navigate(`/leads/${payload.leadID}/profile/tickets/${payload.ticketID}`);
      case "NEW.LEAD":
        return navigate(`/leads/${payload.leadiD}/profile/overview`);
      case "NEW.QUERY":
        return navigate(`/leads/${payload.leadID}/profile/queries/${payload.queryID}`);
      case "OFFER.ACCEPTED":
        return navigate(`/leads/${payload.leadID}/profile/offers/${payload.offerID}`);
      case "OFFER.REMINDER":
        return navigate(`/leads/${payload.leadID}/profile/offers/${payload.offerID}`);
    }
    return;
  }, []);

  return (
    <Provider
      value={{
        notifications,
        methods: {
          removeAll: _handleRemoveAll,
          seen: _handleSeen,
          click: _handleClick,
        },
      }}
    >
      {children}
    </Provider>
  );
};
export { NotificationContext, NotificationProvider };
