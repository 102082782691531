import clx from "classnames";
const getIcon = (code) => {
  const resources = {
    whatsapp: "whatsapp.png", // source, channel
    voiceCall: "telephone.png", // source
    FBMessenger: "facebook.png", // source
    FBMessaging: "facebook.png", // channel
    facebook: "facebook.png", // channel
    FBLead: "facebook.png", // source
    instagram: "instagram.png", // channel
    form: "website.png",
    sms: "sms.png",
    manual: "manual.png",
    messenger: "messenger.png",
    "video/mp4": "mp4.png",
    "video/mpeg": "mpeg.png",
    "audio/mpeg": "mp3.png",
    "image/jpeg": "jpeg.png",
    "image/png": "png.png",
    "application/zip": "zip.png",
    "application/vnd.rar": "rar.png",
    "application/pdf": "pdf.png",
    "audio/ogg": "ogg.png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "sheets.png",
    invoice: "invoice.png",
    verified: "verified.png",
    "application/msword": "word.png",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "word.png",
  };
  return resources[code] ?? "";
};
export default function AppImage({ code, onClick = () => {}, className, ...props }) {
  return <img src={`/media/icons/${getIcon(code)}`} onClick={onClick} className={clx("object-contain", className)} {...props} />;
}
