import DashboardPage from "@modules/dashboard/DashboardPage";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import RouteContainer from "../components/core/RouteContainer";
import MasterLayout from "../components/layouts/MasterLayout";

const SettingsPage = lazy(() => import("@modules/settings/SettingsPage"));
const QueryPage = lazy(() => import("@modules/queries/QueryPage"));
const OfferPage = lazy(() => import("@modules/offers/OfferPage"));
const PolicyPage = lazy(() => import("@modules/policies/PolicyPage"));
const LeadPage = lazy(() => import("@modules/leads/LeadPage"));
const ErrorPage = lazy(() => import("@modules/error/ErrorPage"));
const ProfilePage = lazy(() => import("@modules/profile/ProfilePage"));
const TicketPage = lazy(() => import("@modules/tickets/TicketPage"));
const NotificationPage = lazy(() => import("@modules/notifications/NotificationPage"));
const ReportPage = lazy(() => import("@modules/reports/ReportPage"));
const MediaPage = lazy(() => import("@modules/medias/MediaPage"));
const CommissionPage = lazy(() => import("@modules/commissions/CommissionPage"));

const paths = [
  { path: "/auth/*", element: <Navigate to="/dashboard" /> },
  { path: "/dashboard/*", element: <DashboardPage /> },
  { path: "/error/*", element: <ErrorPage code={404} /> },
  { path: "/leads/*", element: <LeadPage /> },
  { path: "/queries/*", element: <QueryPage /> },
  { path: "/offers/*", element: <OfferPage /> },
  { path: "/policies/*", element: <PolicyPage /> },
  { path: "/settings/*", element: <SettingsPage /> },
  { path: "/profile/*", element: <ProfilePage /> },
  { path: "/tickets/*", element: <TicketPage /> },
  { path: "/notifications/*", element: <NotificationPage /> },
  { path: "/reports/*", element: <ReportPage /> },
  { path: "/medias/*", element: <MediaPage /> },
  { path: "/commissions/*", element: <CommissionPage /> },
];

export default function PrivateRoutes() {
  return <RouteContainer paths={paths} layout={<MasterLayout />} />;
}
