import useSocket from "@hooks/useSocket";
import { createContext } from "react";

const AppContext = createContext();
const { Provider } = AppContext;

const AppConfigProvider = ({ children }) => {
  useSocket(); // Initiate SocketIO
  return <Provider value={{}}>{children}</Provider>;
};
export { AppConfigProvider, AppContext };
