import { Suspense, lazy } from "react";

const ArrowDownTrayIcon = lazy(() => import("@heroicons/react/24/outline/ArrowDownTrayIcon"));
const PhotoIcon = lazy(() => import("@heroicons/react/24/outline/PhotoIcon"));
const ArrowPathIcon = lazy(() => import("@heroicons/react/24/outline/ArrowPathIcon"));
const ArrowsUpDownIcon = lazy(() => import("@heroicons/react/24/outline/ArrowsUpDownIcon"));
const BellAlertIcon = lazy(() => import("@heroicons/react/24/outline/BellAlertIcon"));
const BoltIcon = lazy(() => import("@heroicons/react/24/outline/BoltIcon"));
const ChevronUpDownIcon = lazy(() => import("@heroicons/react/24/outline/ChevronUpDownIcon"));
const CloudArrowDownIcon = lazy(() => import("@heroicons/react/24/outline/CloudArrowDownIcon"));
const CloudArrowUpIcon = lazy(() => import("@heroicons/react/24/outline/CloudArrowUpIcon"));
const CursorArrowRaysIcon = lazy(() => import("@heroicons/react/24/outline/CursorArrowRaysIcon"));
const EllipsisVerticalIcon = lazy(() => import("@heroicons/react/24/outline/EllipsisVerticalIcon"));
const FaceSmileIcon = lazy(() => import("@heroicons/react/24/outline/FaceSmileIcon"));
const FunnelIcon = lazy(() => import("@heroicons/react/24/outline/FunnelIcon"));
const HashtagIcon = lazy(() => import("@heroicons/react/24/outline/HashtagIcon"));
const InboxIcon = lazy(() => import("@heroicons/react/24/outline/InboxIcon"));
const LifebuoyIcon = lazy(() => import("@heroicons/react/24/outline/LifebuoyIcon"));
const MapIcon = lazy(() => import("@heroicons/react/24/outline/MapIcon"));
const MapPinIcon = lazy(() => import("@heroicons/react/24/outline/MapPinIcon"));
const MicrophoneIcon = lazy(() => import("@heroicons/react/24/outline/MicrophoneIcon"));
const MinusCircleIcon = lazy(() => import("@heroicons/react/24/outline/MinusCircleIcon"));
const MinusIcon = lazy(() => import("@heroicons/react/24/outline/MinusIcon"));
const NoSymbolIcon = lazy(() => import("@heroicons/react/24/outline/NoSymbolIcon"));
const PaperAirplaneIcon = lazy(() => import("@heroicons/react/24/outline/PaperAirplaneIcon"));
const PaperClipIcon = lazy(() => import("@heroicons/react/24/outline/PaperClipIcon"));
const PencilIcon = lazy(() => import("@heroicons/react/24/outline/PencilIcon"));
const PhoneIcon = lazy(() => import("@heroicons/react/24/outline/PhoneIcon"));
const PlusCircleIcon = lazy(() => import("@heroicons/react/24/outline/PlusCircleIcon"));
const PlusIcon = lazy(() => import("@heroicons/react/24/outline/PlusIcon"));
const QuestionMarkCircleIcon = lazy(() => import("@heroicons/react/24/outline/QuestionMarkCircleIcon"));
const ShareIcon = lazy(() => import("@heroicons/react/24/outline/ShareIcon"));
const TagIcon = lazy(() => import("@heroicons/react/24/outline/TagIcon"));
const TrashIcon = lazy(() => import("@heroicons/react/24/outline/TrashIcon"));
const XMarkIcon = lazy(() => import("@heroicons/react/24/outline/XMarkIcon"));
const ExclamationCircleIcon = lazy(() => import("@heroicons/react/24/outline/ExclamationCircleIcon"));
const ExclamationTriangleIcon = lazy(() => import("@heroicons/react/24/outline/ExclamationTriangleIcon"));
const EyeIcon = lazy(() => import("@heroicons/react/24/outline/EyeIcon"));
const ChatBubbleLeftRightIcon = lazy(() => import("@heroicons/react/24/outline/ChatBubbleLeftRightIcon"));
const ChartBarIcon = lazy(() => import("@heroicons/react/24/outline/ChartBarIcon"));
const UsersIcon = lazy(() => import("@heroicons/react/24/outline/UsersIcon"));
const UserIcon = lazy(() => import("@heroicons/react/24/outline/UserIcon"));
const ChevronDownIcon = lazy(() => import("@heroicons/react/24/outline/ChevronDownIcon"));
const ArrowLeftIcon = lazy(() => import("@heroicons/react/24/outline/ArrowLeftIcon"));
const ArrowRightIcon = lazy(() => import("@heroicons/react/24/outline/ArrowRightIcon"));
const ArrowsRightLeftIcon = lazy(() => import("@heroicons/react/24/outline/ArrowsRightLeftIcon"));
const Bars3Icon = lazy(() => import("@heroicons/react/24/outline/Bars3Icon"));
const BellSlashIcon = lazy(() => import("@heroicons/react/24/outline/BellSlashIcon"));
const BookmarkIcon = lazy(() => import("@heroicons/react/24/outline/BookmarkIcon"));
const BookOpenIcon = lazy(() => import("@heroicons/react/24/outline/BookOpenIcon"));
const CalendarIcon = lazy(() => import("@heroicons/react/24/outline/CalendarIcon"));
const CheckCircleIcon = lazy(() => import("@heroicons/react/24/outline/CheckCircleIcon"));
const CheckIcon = lazy(() => import("@heroicons/react/24/outline/CheckIcon"));
const ChevronLeftIcon = lazy(() => import("@heroicons/react/24/outline/ChevronLeftIcon"));
const ChevronRightIcon = lazy(() => import("@heroicons/react/24/outline/ChevronRightIcon"));
const ChevronUpIcon = lazy(() => import("@heroicons/react/24/outline/ChevronUpIcon"));
const CircleStackIcon = lazy(() => import("@heroicons/react/24/outline/CircleStackIcon"));
const ClipboardDocumentIcon = lazy(() => import("@heroicons/react/24/outline/ClipboardDocumentIcon"));
const ClockIcon = lazy(() => import("@heroicons/react/24/outline/ClockIcon"));
const CogIcon = lazy(() => import("@heroicons/react/24/outline/CogIcon"));
const InformationCircleIcon = lazy(() => import("@heroicons/react/24/outline/InformationCircleIcon"));
const HomeIcon = lazy(() => import("@heroicons/react/24/outline/HomeIcon"));
const ShieldCheckIcon = lazy(() => import("@heroicons/react/24/outline/ShieldCheckIcon"));
const DocumentIcon = lazy(() => import("@heroicons/react/24/outline/DocumentIcon"));
const EuroIcon = lazy(() => import("@heroicons/react/24/outline/CurrencyEuroIcon"));

const _FAHERO = {
  "fa-address-book": BookmarkIcon,
  "fa-arrow-left": ArrowLeftIcon,
  "fa-arrow-right": ArrowRightIcon,
  "fa-arrows-alt": ArrowsRightLeftIcon,
  "fa-bars": Bars3Icon,
  "fa-bell-slash": BellSlashIcon,
  "fa-bell": BellAlertIcon,
  "fa-book": BookOpenIcon,
  "fa-bookmark": BookmarkIcon,
  "fa-calendar": CalendarIcon,
  "fa-caret-down": ChevronDownIcon,
  "fa-caret-right": ChevronRightIcon,
  "fa-check": CheckIcon,
  "fa-chart": ChartBarIcon,
  "fa-check-circle": CheckCircleIcon,
  "fa-chevron-down": ChevronDownIcon,
  "fa-chevron-left": ChevronLeftIcon,
  "fa-chevron-right": ChevronRightIcon,
  "fa-chevron-up": ChevronUpIcon,
  "fa-circle": MinusCircleIcon,
  "fa-clock": ClockIcon,
  "fa-cog": CogIcon,
  "fa-inbox": InboxIcon,
  "fa-coins": CircleStackIcon,
  "fa-compass": FunnelIcon,
  "fa-copy": ClipboardDocumentIcon,
  "fa-drag": ArrowsUpDownIcon,
  "fa-dashboard": HomeIcon,
  "fa-download": ArrowDownTrayIcon,
  "fa-ellipsis-v": EllipsisVerticalIcon,
  "fa-exclamation-circle": ExclamationCircleIcon,
  "fa-exclamation-triangle": ExclamationTriangleIcon,
  "fa-eye": EyeIcon,
  "fa-export": CloudArrowDownIcon,
  "fa-filter": FunnelIcon,
  "fa-info-circle": InformationCircleIcon,
  "fa-map": MapIcon,
  "fa-map-marker-alt": MapPinIcon,
  "fa-pen": PencilIcon,
  "fa-phone": PhoneIcon,
  "fa-plus": PlusIcon,
  "fa-plus-circle": PlusCircleIcon,
  "fa-share": ShareIcon,
  "fa-shield": ShieldCheckIcon,
  "fa-sms": ChatBubbleLeftRightIcon,
  "fa-sync": ArrowPathIcon,
  "fa-spinner": ArrowPathIcon,
  "fa-times": XMarkIcon,
  "fa-trash": TrashIcon,
  "fa-upload": CloudArrowUpIcon,
  "fa-users": UsersIcon,
  "fa-user": UserIcon,
  "fa-plus": PlusIcon,
  "fa-minus": MinusIcon,
  "fa-smile": FaceSmileIcon,
  "fa-microphone": MicrophoneIcon,
  "fa-paper-plane": PaperAirplaneIcon,
  "fa-paperclip": PaperClipIcon,
  "fa-chevron-up-down": ChevronUpDownIcon,
  "fa-bolt": BoltIcon,
  "fa-hashtag": HashtagIcon,
  "fa-no-symbol": NoSymbolIcon,
  "fa-marketing": CursorArrowRaysIcon,
  "fa-book": BookOpenIcon,
  "fa-question": QuestionMarkCircleIcon,
  "fa-tag": TagIcon,
  "fa-support": LifebuoyIcon,
  "fa-send": PaperAirplaneIcon,
  "fa-document": DocumentIcon,
  "fa-photo": PhotoIcon,
  "fa-euro": EuroIcon,
};

export default function AppIcon({ code = "", className = "", ...props }) {
  if (!code || !_FAHERO[code]) return <></>;
  const Item = _FAHERO[code];
  return (
    <Suspense fallback={<></>}>
      <Item className={className} {...props} />
    </Suspense>
  );
}
