import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@context/AuthContext";

export default function LogoutScreen() {
  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    logout();
    return navigate("/auth/login");
  }, []);

  return <></>;
}
