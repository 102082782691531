import { createContext, useEffect, useState } from "react";

const AppLayoutContext = createContext();
const { Provider } = AppLayoutContext;

const AppLayoutProvider = ({ children }) => {
  /** */

  // Window Dimensions
  const [dimensions, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });

  // Notifications Drawer
  const [notification, setNotification] = useState({ enabled: false });

  // Screen Width Update Handler
  const handleWindowSizeChange = () => setDimensions({ height: window.innerHeight, width: window.innerWidth });

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);

  const components = {
    notification: {
      enabled: notification.enabled,
      show: () => setNotification(() => ({ enabled: true })),
      close: () => setNotification(() => ({ enabled: false })),
    },
  };

  return (
    <Provider
      value={{
        ...components,
        dimensions,
        isMobile: dimensions.width < 768,
      }}
    >
      {children}
    </Provider>
  );
};
export { AppLayoutContext, AppLayoutProvider };
