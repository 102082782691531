import AppButton from "./AppButton";
import clx from "classnames";
export default function CrudButtons({
  onSubmit = () => {},
  onCancell = () => {},
  loading = false,
  disabled = false,
  title = "T.SAVE",
  className = "justify-between w-full",
}) {
  return (
    <div className={clx("flex items-center space-x-2", className)}>
      <AppButton onClick={onCancell} title="T.CANCEL" />
      <AppButton mode="primary" title={title} loading={loading} disabled={disabled} onClick={onSubmit} />
    </div>
  );
}
